<template>
  <v-row class="ml-md-0 pt-5">
    <h2 class="mb-0 secondary--text font-weight-bold">{{ $t("signIn") }}</h2>
    <span>
      {{ isPhoneLogin ? $t("enterPhonePassword") : $t("enterEmailPassword") }}
    </span>
    <template v-if="!isPhoneLogin">
      <v-col cols="12">
        <custom-text-field
          :customLabel="$t('emailAddress')"
          :hide-details="emailError.length === 0"
          :error-count="emailError.length"
          :error-messages="emailError"
          v-model="email"
          required
          color="main_text_color"
        />
      </v-col>
    </template>
    <template v-else>
      <v-col cols="4" class="py-0 pl-0 d-flex mt-4">
        <custom-auto-complete
          :items="phoneCodes"
          :customLabel="$t('countryCode')"
          prefix="+"
          :hide-details="phoneCodeError.length === 0"
          :error-count="phoneCodeError.length"
          :error-messages="phoneCodeError"
          item-text="code"
          item-value="code"
          return-object
          v-model="phoneInternationalInfos"
          color="#59D79D"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-subtitle>
                <flag :iso="data.item.country" />
                <span class="pl-2">{{ data.item.code }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </custom-auto-complete>
      </v-col>
      <v-col cols="8" class="p-0 mt-4">
        <custom-text-field
          :customLabel="$t('phoneNumber')"
          @keypress="onlyNumber"
          :counter="phoneInternationalInfos.phoneMask.length"
          required
          :maxlength="phoneInternationalInfos.phoneMask.length"
          color="#59D79D"
          :error-count="phoneError.length"
          :error-messages="phoneError"
          v-model="phoneNumber"
          v-mask="phoneInternationalInfos.phoneMask"
        />
      </v-col>
    </template>
    <v-col cols="12 relative">
      <custom-password-field
        :customLabel="$t('password')"
        required
        @keydown.enter="onSubmit"
        customType="password"
        :hide-details="errors.length === 0"
        color="main_text_color"
        :error-count="errors.length"
        :error-messages="errors"
        v-model="password"
        />
    </v-col>
    <v-col cols="12">
      <router-link
        :to="{ name: 'auth1.password-reset' }"
        class="float-end secondary--text"
      >
        {{ $t("forgotPassword") }} ?
      </router-link>
    </v-col>
    <v-col cols="12">
        <save-button :save="onSubmit" :loading="loading" :message="$t('signIn')" cssClasses="w-100 p-4 border-radius-small" color="secondary"/>
    </v-col>
    <v-col col="12">
      <v-btn class="border-radius-small v-size--small w-100 p-4 v-btn--is-elevated white hint_text_color--text"
        color="secondary"
        style="font-size: .75rem;"
        outlined
        @click="toggleLoginMode"
      >
        {{ isPhoneLogin ? $t("loginWithEmail") : $t("loginWithPhone") }}
      </v-btn>
    </v-col>
    <v-col col="12">
      <social-action-button :onClick="loginGoogle" socialName="google" :text="$t('loginGoogle')" />
    </v-col>
    <v-col cols="12">
      <social-action-button :onClick="loginFacebook" socialName="facebook" :text="$t('loginFacebook')" />
    </v-col>
    <v-col
      cols="12"
      class="text-center"
    >
      <span class="dark-color d-inline-block line-height-2"
        >{{ $t("dontHaveAccount") }} ?
        <router-link :to="{ name: 'auth1.sign-up1' }" class="secondary--text">{{
          $t("signUp")
        }}</router-link>
      </span>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment/moment';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import userDataMixin from '@/mixins/userDataMixin';
import SocialActionButton from '@/components/socialvue/buttons/SocialActionButton.vue';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';
import CustomPasswordField from '@/components/socialvue/customPasswordField/CustomPasswordField.vue';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { typeOnlyNumber, getPhoneCodes, removeMaskFromPhoneNumber } from '@/misc/utils';
import CustomAutoComplete from '@/components/socialvue/customAutocomplete/CustomAutoComplete.vue';
import { mask } from 'vue-the-mask';

export default {
  name: 'SignIn1',
  directives: { mask },
  components: { SaveButton, SocialActionButton, CustomTextField, CustomPasswordField, CustomAutoComplete },
  mixins: [errorDialog, userDataMixin],
  data () {
    const phoneCodes = getPhoneCodes();
    const defaultPhoneInternationalInfos = phoneCodes[0];
    return {
      errors: [],
      emailError: [],
      loading: false,
      email: '',
      query: {},
      password: '',
      showPassword: false,
      user: {},
      phoneCodes: phoneCodes,
      phoneInternationalInfos: defaultPhoneInternationalInfos,
      phoneNumber: '',
      phoneCodeError: [],
      phoneError: [],
      isPhoneLogin: false

    };
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  created () {
    if (this.$route.params.query) {
      this.query = JSON.parse(this.$route.params.query);
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    toggleLoginMode () {
      this.isPhoneLogin = !this.isPhoneLogin;
      this.password = '';
      this.errors = [];
    },
    onlyNumber (event) {
      return typeOnlyNumber(event);
    },
    validatePhone (phone, phoneInternationalInfos) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(removeMaskFromPhoneNumber(phone), phoneInternationalInfos.country),
        phoneInternationalInfos.country
      );
    },
    createFreeSubscription () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/orders`;
      const newDate = moment();
      const now = moment(moment.utc(newDate)).format('DD-MM-YYYY');
      const formData = {
        subscription_id: 1,
        user_id: this.query.userId,
        status: 20,
        price: 0,
        payment_date: now
      };
      axios
        .post(url, formData)
        .then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('successfulFreePlanSubscription'),
            confirmButtonColor: '#19AD93'
          });
          this.$router.push({ name: 'social.list' });
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    loginGoogle () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/google';
    },
    loginFacebook () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/facebook';
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resendVerifyEmail () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/user/verification/resend`;
      const data = {
        email: this.email
      };
      axios
        .post(url, data)
        .then(() => {})
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    sendDataSignInLayerInfo () {
      this.trackUserData('sign_in', {
        register_type: 'prosk'
      })
    },
    accountNotVerified (error) {
      return error.response.data.errors && error.response.data.errors.login && error.response.data.errors.login.find(item => item === 'userHasNotBeenVerified');
    },
    createJob (selectedService, startDate) {
      const formData = {
        prosker_user_id: selectedService.user_id,
        client_user_id: this.currentUser.id,
        title: selectedService.name,
        description: selectedService.description,
        prosker_approval: null,
        client_approval: null,
        price: selectedService.price,
        start_date: startDate,
        end_date: selectedService.end_date || null,
        payment_required: selectedService.payment_required,
        reason_help: null,
        service_id: selectedService.id
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/service/${selectedService.id}/create-job`;
      return axios
        .post(url, formData)
        .then(() => {
          console.log('Job creado exitosamente');
          return true;
        })
        .catch((error) => {
          console.error('Error creating the job:', error);
          throw error;
        });
    },
    onSubmit () {
      if (this.phoneNumber) {
        this.phoneNumber = this.phoneNumber.replace(/\s+/g, '');
      }
      this.errors = [];
      this.emailError = [];
      this.phoneCodeError = [];
      this.phoneError = [];
      const passwordValidation = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
      if (this.email === '' && !this.phoneNumber) {
        this.emailError.push(this.$t('emailRequired'));
        this.phoneError.push(this.$t('phoneNumberRequired'));
      } else {
        if (this.email !== '' && !this.validEmail(this.email)) {
          this.emailError.push(this.$t('enterValidEmail'));
        }
        if (this.phoneNumber && !this.validatePhone(this.phoneNumber, this.phoneInternationalInfos)) {
          this.phoneError.push(this.$t('invalidPhoneNumber'));
        }
      }
      if (this.password === '') {
        this.errors.push(this.$t('passwordRequired'));
      }
      if (!this.password.match(passwordValidation)) {
        this.errors.push(this.$t('invalidPassword'));
      }
      if (this.password.match(passwordValidation) && this.password !== '') {
        if ((this.email !== '' && this.validEmail(this.email)) || (this.phoneNumber && this.validatePhone(this.phoneNumber, this.phoneInternationalInfos))) {
        this.loading = true;
        const user = {
          email: this.email,
          phone_code: this.phoneInternationalInfos.code,
          phone: this.phoneNumber,
          password: this.password
        };
        this.signIn(user)
          .then(() => {
            this.loading = false;
            this.sendDataSignInLayerInfo();
            const startDate = localStorage.getItem('prosk_startDate');
            const selectedService = JSON.parse(localStorage.getItem('prosk_selectedService') || 'null');
            if (selectedService) {
              if (selectedService.payment_required === 0) {
                return this.createJob(selectedService, startDate)
                  .then(() => {
                    this.$swal.fire({
                      title: this.$t('awesome'),
                      text: this.$t('jobCreatedSuccessfully'),
                      confirmButtonColor: '#19AD93'
                    });
                    this.$router.push({ name: 'social.services' });
                    localStorage.removeItem('prosk_startDate');
                    localStorage.removeItem('prosk_selectedService');
                  });
              } else {
                this.$router.push({
                  name: 'checkout-service'
                });
                return;
              }
            }
            if (this.query.isFreeSubscription) {
              this.createFreeSubscription();
            } else {
              this.$router.push({ name: 'social.list' });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error, true);
            if (this.accountNotVerified(error)) {
              this.resendVerifyEmail();
            }
        });
        }
      }
    }
  },
  mounted () {
      this.trackUserData('load_content', {
      pageType: 'register/loggin'
    });
  }
};
</script>
<style scoped>
  .relative {
    position: relative;
    width: 100%;
  }

  .absolute {
    position: absolute;
    top: 55%;
    right: 0px;
    transform: translate(-50%, -50%);
  }

  @media(max-width: 768px) {
    .absolute {
    top: 45%;
    right: 0px;
  }
  }
</style>
