<template>
  <div :class="[{ 'full-width': fullWidth }]">
    <label v-show="customLabel" class="pb-0 mb-0">{{ customLabel }}</label>
    <div>
      <v-text-field
        class="password-field"
        v-bind="$attrs"
        v-on="click ? { click } : {}"
        @input="onInput"
        :class="[
          'custom-password-field',
          {
            flat,
            [`icon-pos-${iconPosition}`]: !!icon
          },
          cssClasses
        ]"
        outlined
        :append-icon="icon"
        :type="chooseType()"
        v-model="password"
      >
        <template v-slot:append>
          <v-icon @click="onShowPassword">
            {{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomPasswordField',
  data () {
    return {
      showPassword: false,
      password: '' // Campo para almacenar la contraseña
    };
  },
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'prepend'
    },
    cssClasses: {
      type: String,
      default: ''
    },
    click: {
      type: Function
    },
    customLabel: {
      type: String,
      default: 'Password'
    }
  },
  methods: {
    onInput (value) {
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    chooseType () {
      return this.showPassword ? 'text' : 'password';
    }
  }
};
</script>

<style scoped>
.custom-password-field {
  width: 100%;
}

.password-field ::v-deep .v-text-field__slot input {
  color: #212833 !important;
}

.password-field ::v-deep .v-input__slot {
  border-radius: 16px !important;
  padding: 0 16px !important;
}

.password-field ::v-deep .v-input__slot fieldset {
  top: 5px !important;
  bottom: 5px !important;
  border-color: #606A76 !important;
  background-color: white !important;
}

.password-field ::v-deep .v-input__slot {
  background-color: transparent !important;
}
</style>
